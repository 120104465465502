import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Import your components here
import HomePage from './HomePage'; 
import HelpPage from './HelpPage';
import LegalPage from './LegalPage';
import CreditsPage from './CreditsPage';
import Header from './Header'; 
import Footer from './Footer'; 

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col">
        <Header />

        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/help" component={HelpPage} key="/help" />
          <Route path="/legal" component={LegalPage} key="/legal" />
          <Route path="/credits" component={CreditsPage} key="/credits" />
        </Switch>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
